import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import LogoUrl from './logo.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p align="center">
  <img width="701" src={LogoUrl} alt="Redoute" align="center" />
    </p>
    <h1 {...{
      "id": "redoute-"
    }}>{`Redoute 📚`}</h1>
    <p><strong parentName="p">{`Redoute`}</strong>{` is an UI kit. It allows you to install and import small components for your interface.`}</p>
    <p>{`Packages are located in `}<inlineCode parentName="p">{`src/packages`}</inlineCode>{`.`}<br parentName="p"></br>{`
`}{`They are independents.`}</p>
    <p>{`Online doc : `}<a parentName="p" {...{
        "href": "https://www.redoute.dataveyes.com/"
      }}>{`redoute.dataveyes.com`}</a></p>
    <h3 {...{
      "id": "all-components-are-listed-in-the-doc"
    }}>{`All components are listed in the doc`}</h3>
    <p><inlineCode parentName="p">{`yarn run docz:dev`}</inlineCode></p>
    <h1 {...{
      "id": "dev-zone-"
    }}>{`Dev zone 👩‍💻`}</h1>
    <h3 {...{
      "id": "how-to-createupdate-and-publish-a-repository"
    }}>{`How to create/update and publish a repository`}</h3>
    <ol>
      <li parentName="ol">{`Create a new folder for your package/components in `}<inlineCode parentName="li">{`src/packages`}</inlineCode></li>
      <li parentName="ol">{`Develop your components`}</li>
      <li parentName="ol">{`Write the document in a .mdx file & check in the docz website (If needed install it localy, see chapter about `}<inlineCode parentName="li">{`yarn link`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Push and create a Pull Review for a peer to check (do not merge yet!)`}</li>
      <li parentName="ol">{`When the review is finished, squash/rebase the history to have a clean history.`}</li>
      <li parentName="ol">{`Pull the last version of master on your branch, publish the version by running `}<inlineCode parentName="li">{`yarn run publish`}</inlineCode>{` (you may need to run `}<inlineCode parentName="li">{`yarn login`}</inlineCode>{` first)`}</li>
      <li parentName="ol">{`Push that commit to your branch (it may has been pushed automatically with the previous command)`}</li>
      <li parentName="ol">{`Merge this branch`}</li>
    </ol>
    <h3 {...{
      "id": "yarn-link--install-a-package-from-a-local-path-without-publishing-it-"
    }}>{`yarn link : Install a package from a local path (without publishing it) ?`}</h3>
    <p>{`Init the link:`}</p>
    <pre><code parentName="pre" {...{}}>{`cd src/packages/my-package
yarn link
`}</code></pre>
    <p>{`Install the new package where you want to use it:`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn link @redoute/my-package
`}</code></pre>
    <p>{`More about `}<a parentName="p" {...{
        "href": "https://yarnpkg.com/lang/en/docs/cli/link/"
      }}>{`yarn link`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      